import type { Components, Theme } from '@mui/material/styles';

import { radioClasses } from '@mui/material/Radio';

const MuiRadio: Components<Theme>['MuiRadio'] = {
  /** **************************************
   * DEFAULT PROPS
   *************************************** */
  defaultProps: { size: 'small' },

  /** **************************************
   * STYLE
   *************************************** */
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      padding: theme.spacing(1),
      ...(ownerState.color === 'default' && {
        [`&.${radioClasses.checked}`]: { color: theme.vars.palette.text.primary },
      }),
      [`&.${radioClasses.disabled}`]: { color: theme.vars.palette.action.disabled },
    }),
  },
};

// ----------------------------------------------------------------------

export const radio = { MuiRadio };
