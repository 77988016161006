import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export const Detail = ({ label, value }: { label: string; value?: React.ReactNode }) => (
  <Stack direction="row" spacing={4} alignItems="center">
    <Typography variant="body2" color="text.secondary" sx={{ minWidth: 150 }}>
      {label}
    </Typography>
    <Typography variant="body2" sx={{ flex: 1 }}>
      {value ?? 'N/A'}
    </Typography>
  </Stack>
);

export default Detail;
