import SvgColor from '@/components/SvgColor';
import { paths } from '@/constants';
import { CustomerType } from '@/enums';
import { useUserProfile } from '@/hooks/useUsers';
import { useMemo } from 'react';

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

export const ICONS = {
  blog: icon('ic-blog'),
  user: icon('ic-user'),
  file: icon('ic-file'),
  banking: icon('ic-banking'),
  analytics: icon('ic-analytics'),
  dashboard: icon('ic-dashboard'),
};

export function useNavData() {
  const { organizationCustomerType } = useUserProfile();

  return useMemo(
    () => [
      {
        items: [
          {
            title: 'Home',
            path: paths.home,
            icon: ICONS.dashboard,
          },
          {
            title: 'Orders',
            path: paths.orders,
            icon: ICONS.file,
            children:
              organizationCustomerType === CustomerType.lender
                ? [
                    {
                      title: 'Create New Order',
                      path: `${paths.orders}/create`,
                    },
                  ]
                : [],
          },
          ...(organizationCustomerType === CustomerType.admin
            ? [
                {
                  title: 'Reports',
                  path: paths.reports,
                  icon: ICONS.analytics,
                },
              ]
            : []),
          ...(organizationCustomerType === CustomerType.admin
            ? [
                {
                  title: 'Lender Management',
                  path: paths.lenders,
                  icon: ICONS.banking,
                },
              ]
            : []),

          ...(organizationCustomerType === CustomerType.admin &&
          import.meta.env.VITE_VIEW_VENDORS === 'true'
            ? [{ title: 'Vendor Management', path: paths.vendors, icon: ICONS.blog }]
            : []),
        ],
      },
    ],
    [organizationCustomerType]
  );
}
